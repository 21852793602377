import dropzone from 'dropzone'

Dropzone.autoDiscover = false;

$(document).ready(function () {
    $("#my-dropzone").dropzone({
        maxFiles: 2000,
        url: "/index/issue_application/",
        success: function (file, response) {
            console.log(response);
        }
    });
})

// import tns from 'tiny-slider'
// import 'custom/tns.js'
import "custom/script.js"